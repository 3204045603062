.switch {
  position: relative;
  display: inline-block;
  margin: 0;
}

.caption {
  display: block;
  margin-left: theme('spacing.2');
  color: theme('colors.tertiary500');
  text-align: left;
  word-break: break-all;
  margin-left: theme('spacing.2');
}

.slider,
.sliderPrimary {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: theme('colors.tertiary300');
  transition: 0.4s;
  pointer-events: none;

  &:before {
    box-shadow: theme('boxShadow.3');
    position: absolute;
    content: '';
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
}

.input {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:checked + .slider {
    background-color: theme('colors.secondary500');
  }

  &:disabled:checked + .slider,
  &:disabled:checked + .sliderPrimary {
    background-color: theme('colors.secondary50');
  }

  &:disabled + .slider,
  &:disabled + .sliderPrimary {
    background-color: theme('colors.tertiary200');
  }

  &:checked + .sliderPrimary {
    background-color: theme('colors.primary500');
  }

  &:disabled ~ .caption {
    color: theme('colors.tertiary200');
  }
}

[data-size='small'] {
  &.switch {
    height: theme('spacing.4');
    padding-left: 1.625rem;
  }

  .slider,
  .sliderPrimary {
    width: 1.625rem;
    border-radius: theme('spacing.4');

    &:before {
      height: theme('spacing.3');
      width: theme('spacing.3');
      left: 2px;
      bottom: 2px;
    }
  }

  .input:checked + .slider:before,
  .input:checked + .sliderPrimary:before {
    transform: translateX(calc(100% - 2px));
  }

  .caption {
    @apply text-caption-sm-regular;
    line-height: theme('spacing.4');
  }
}

[data-size='large'] {
  &.switch {
    height: theme('spacing.5');
    padding-left: theme('spacing.8');
  }

  .slider,
  .sliderPrimary {
    border-radius: theme('spacing.5');
    width: theme('spacing.8');

    &:before {
      height: theme('spacing.4');
      width: theme('spacing.4');
      left: 2px;
      bottom: 2px;
    }
  }

  .input:checked + .slider:before,
  .input:checked + .sliderPrimary:before {
    transform: translateX(calc(100% - 4px));
  }

  .caption {
    @apply text-caption-lg-regular;
    line-height: theme('spacing.5');
  }
}
