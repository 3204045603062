.simpleTabs {
  display: flex;
  flex-wrap: wrap;
  margin: theme(spacing.0) auto theme(spacing.5);
  border-radius: theme(gap.3) theme(gap.3) theme(spacing.0) theme(spacing.0);
  padding-bottom: theme(spacing.1);
  border-bottom: 1px solid theme('colors.grey25');
}

.tab {
  @apply text-body-sm-regular;
  color: theme('colors.tertiary300');

  position: absolute;
  width: fit-content;
  padding: theme(spacing.4) theme(spacing.4) theme(spacing.1);
  background: white;
  cursor: pointer;
  position: relative;
  top: 4px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  border: none;
}

.tab:hover {
  border-bottom: 4px solid theme('colors.primary500');
}

.tab.active {
  @apply text-body-sm-semibold;
  color: theme('colors.tertiary500');

  background: theme('colors.primary25');
  border-bottom: 4px solid theme('colors.primary500');
}

.tab.active {
  display: block;
}
