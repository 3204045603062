.chips {
  @apply text-caption-sm-semibold;

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: theme('borderRadius.2');

  &[data-clickable='true'] {
    cursor: pointer;
  }
}

.iconLeft,
.iconRight {
  width: theme('spacing.3');
  max-height: theme('spacing.3');
}

.iconLeft {
  margin-right: theme('spacing.1');
}

.iconRight {
  margin-left: theme('spacing.1');
}

[data-only-icon='true'] {
  &.chips {
    justify-content: center;
  }

  .iconLeft,
  .iconRight {
    margin: 0;
  }
}

[data-variant='outline'] {
  &.chips {
    border-width: 1px;
    border-style: solid;
  }
}

[data-size='small'] {
  &.chips {
    height: 18px;
    min-width: 18px;
    padding: 0 theme('spacing.1');
  }
}

[data-size='medium'] {
  &.chips {
    height: theme('spacing.6');
    min-width: theme('spacing.6');
    padding: 0 theme('spacing.2');
  }
}

[data-size='large'] {
  &.chips {
    height: theme('spacing.8');
    min-width: theme('spacing.8');
    padding: 0 theme('spacing.2');
  }
}

[data-color='primary'] {
  &.chips {
    background-color: theme('colors.tertiary25');
    color: theme('colors.tertiary500');
    border-color: theme('colors.tertiary500');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.tertiary50');
      }

      &:active {
        background-color: theme('colors.tertiary500');
        color: theme('colors.primary25');
      }
    }
  }
}

[data-color='secondary'] {
  &.chips {
    background-color: theme('colors.tertiary500');
    color: theme('colors.primary25');
    border-color: theme('colors.tertiary50');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.tertiary600');
      }

      &:active {
        background-color: theme('colors.tertiary700');
      }
    }
  }
}

[data-color='tertiary'] {
  &.chips {
    background-color: theme('colors.tertiary25');
    color: theme('colors.tertiary300');
    border-color: theme('colors.tertiary300');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.tertiary50');
      }

      &:active {
        background-color: theme('colors.tertiary300');
        color: theme('colors.primary25');
      }
    }
  }
}

[data-color='error'] {
  &.chips {
    &[data-variant='normal'] {
      background-color: theme('colors.error25');
    }

    color: theme('colors.error500');
    border-color: theme('colors.error500');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.error25');
      }

      &:active {
        background-color: theme('colors.error500');
        color: theme('colors.primary25');
      }
    }
  }
}

[data-color='success'] {
  &.chips {
    &[data-variant='normal'] {
      background-color: theme('colors.success25');
    }

    color: theme('colors.success500');
    border-color: theme('colors.success500');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.success25');
      }

      &:active {
        background-color: theme('colors.success500');
        color: theme('colors.primary25');
      }
    }
  }
}

[data-color='warning'] {
  &.chips {
    &[data-variant='normal'] {
      background-color: theme('colors.warning25');
    }

    color: theme('colors.warning500');
    border-color: theme('colors.warning500');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.warning25');
      }

      &:active {
        background-color: theme('colors.warning500');
        color: theme('colors.primary25');
      }
    }
  }
}

[data-color='info'] {
  &.chips {
    &[data-variant='normal'] {
      background-color: theme('colors.information25');
    }

    color: theme('colors.information500');
    border-color: theme('colors.information500');

    &[data-clickable='true'] {
      &:hover {
        background-color: theme('colors.information25');
      }

      &:active {
        background-color: theme('colors.information500');
        color: theme('colors.primary25');
      }
    }
  }
}
