.sidebar {
  & ul {
    padding: 0;
  }

  & a {
    &:hover {
      color: theme(colors.primary400);
    }

    &:active {
      color: theme(colors.primary600);
    }
  }

  & li svg:first-child {
    margin-right: theme(spacing.3);
    width: 24px;
    height: 24px;
  }
}

.menu {
  @apply text-body-sm-semibold-respon;

  cursor: default;
  color: theme(colors.tertiary500);

  & .menuLabel {
    border-left: 4px solid transparent;
    padding: theme(spacing.4) theme(spacing.6);
    min-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &[data-collapsible='true'] {
      cursor: pointer;
    }

    &[data-collapsible='true'] .menuTitle {
      padding-right: theme(spacing.4);
      flex: 1;
    }
  }

  &[data-active='true'] .menuLabel {
    color: theme(colors.primary500);
    border-left-color: theme(colors.primary500);
  }

  &[data-level='2'] > span {
    @apply text-body-sm-regular-respon;
    color: theme(colors.tertiary300);
    padding-left: 60px !important;
  }

  &[data-level='3'] > span {
    @apply text-body-sm-regular-respon;
    color: theme(colors.tertiary300);
    padding-left: 80px !important;
  }
}

.submenu {
  @apply text-body-sm-regular-respon;
  color: theme(colors.tertiary300);
  min-height: 56px;
  cursor: default;

  & .label {
    flex: 1;
    display: flex;
    align-items: center;
    min-height: 52px;
    border-left: 4px solid transparent;
    flex-shrink: 0;
    max-width: 250px;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
  }

  &[data-active='true'] .label {
    border-color: theme(colors.primary500);
    color: theme(colors.primary500);

    @apply text-body-sm-semibold-respon;
  }

  & a {
    display: flex;
    align-items: center;
    padding-right: 24px;
  }

  & .badge {
    display: flex;
    background-color: theme(colors.primary500);
    height: 21px;
    border-radius: 30px;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 6px !important;
    @apply text-caption-lg-semibold-respon;
    margin-left: theme(spacing.2);
  }

  & a > span {
    padding-left: 60px !important;
  }

  &[data-level='3'] a > span {
    padding-top: 0.25rem !important;
    padding-left: 80px !important;
  }

  &[data-level='4'] a > span {
    padding-left: 100px !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}

.subList {
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
