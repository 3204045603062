.wrapper {
  display: inline-block;
  position: relative;
  overflow-wrap: break-word;
}

.caption {
  display: block;
  padding-left: theme('spacing.2');
  color: theme('colors.tertiary500');
  text-align: left;
  word-break: break-all;
}

.input[type='radio'] {
  appearance: none;
  background-color: transparent;
  margin: 0;
  position: absolute;
  left: 0;
  cursor: pointer;

  border: 1px solid theme('colors.tertiary300');
  border-radius: 50%;

  display: grid;
  place-content: center;

  & + span {
    cursor: pointer;
  }

  &:disabled,
  &:disabled + span {
    cursor: not-allowed;
  }

  &:not(:checked):disabled {
    border-color: theme('colors.tertiary200');
    background-color: theme('colors.tertiary25');
  }

  &:checked:disabled {
    border-color: theme('colors.secondary100');
  }

  &:checked:disabled::before {
    background-color: theme('colors.secondary100');
  }

  &:disabled ~ .caption {
    color: theme('colors.tertiary200');
  }

  &:checked {
    border-color: theme('colors.secondary500');
  }

  &::before {
    content: '';
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: theme('boxShadow.2');
    background-color: theme('colors.secondary500');
  }

  &:checked::before {
    transform: scale(1);
  }
}

[data-size='small'] {
  &.wrapper {
    min-width: theme('spacing.4');
    min-height: theme('spacing.4');
    padding-left: theme('spacing.4');
  }

  .input[type='radio'] {
    width: theme('spacing.4');
    height: theme('spacing.4');

    &::before {
      width: theme('spacing.2');
      height: theme('spacing.2');
    }
  }

  .caption {
    @apply text-caption-sm-regular;
    line-height: theme('spacing.4');
  }
}

[data-size='large'] {
  &.wrapper {
    min-width: theme('spacing.6');
    min-height: theme('spacing.6');
    padding-left: theme('spacing.6');
  }

  .input[type='radio'] {
    width: theme('spacing.6');
    height: theme('spacing.6');

    &::before {
      width: theme('spacing.3');
      height: theme('spacing.3');
    }
  }

  .caption {
    @apply text-caption-lg-regular;
    line-height: theme('spacing.6');
  }
}
