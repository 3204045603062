.dropdownContent {
  min-width: 248px;
  background-color: white;
  padding: 4px 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1), 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 1000;
}

.dropdownContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.dropdownContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.dropdownContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.dropdownContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.title {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: capitalize;
  padding: 8px 16px;
}

.separator {
  height: 1px;
  background-color: #eef0f7;
  width: 100%;
}

.dropdownItemContainer {
  outline: none;
  padding: 4px 8px;
}

.dropdownItemContent {
  padding: 8px 12px;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  transition-duration: 100ms;
  border-radius: 4px;
}

.dropdownContent[data-variant='main'] .dropdownItemContent:hover {
  background-color: #f5f6f9;
}

.dropdownContent[data-variant='secondary'] .dropdownItemContent:hover {
  background-color: #eaedf9;
  color: #2b50c8;
  font-weight: 600;
}

.footer {
  padding: 16px;
}
