.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputBox {
  width: 36px;
  height: 48px;
  border: 1px solid theme(colors.tertiary50);
  border-radius: 8px;
  text-align: center;
  outline: none;
  color: theme(colors.tertiary500);

  @apply text-body-sm-bold-respon;

  &:focus {
    border-color: theme(colors.secondary500);
  }

  &[data-color='success'] {
    border-color: theme(colors.success500);
  }

  &[data-color='error'] {
    border-color: theme(colors.error500);
  }
}

.inputContainer {
  display: flex;
  gap: theme(spacing.2);
}

.helperText {
  margin-top: theme(spacing.2);
  @apply text-caption-sm-regular-respon;

  &[data-color='success'] {
    color: theme(colors.success500);
  }

  &[data-color='error'] {
    color: theme(colors.error500);
  }
}

.informationText {
  margin-top: theme(spacing.4);
  @apply text-caption-lg-regular-respon;

  & a {
    color: theme(colors.secondary500);
    @apply text-caption-lg-semibold-respon;
  }
}
