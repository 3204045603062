.button {
  border: none;
  background: none;
  cursor: pointer;
  border-radius: theme(spacing.2);
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }

  .iconLeft + span {
    margin-left: 8px;
  }

  & .iconRight {
    margin-left: 8px;
  }
}

.button--variantSolid {
  background-color: theme('colors.primary500') !important;
  color: white !important;

  &:focus {
    background-color: theme('colors.primary500') !important;
    box-shadow: inset 0px 0px 0px 2px theme('colors.primary200') !important;
  }

  &:hover {
    background-color: theme('colors.primary600') !important;
  }

  &:active {
    background-color: theme('colors.primary700') !important;
    box-shadow: none !important;
  }

  &:disabled {
    color: theme('colors.tertiary200') !important;
    background-color: theme('colors.tertiary25') !important;
  }
}

.button--variantSoft {
  background-color: theme('colors.tertiary25');
  color: theme('colors.secondary500');

  &:focus {
    background-color: theme('colors.secondary25');
    color: theme('colors.secondary500');
    outline: 1px solid theme('colors.secondary400');
    box-shadow: inset 0px 0px 0px 2px theme('colors.secondary200');
  }

  &:hover {
    background-color: theme('colors.secondary50');
    color: theme('colors.secondary600');
  }

  &:active {
    background-color: theme('colors.secondary100');
    color: theme('colors.secondary700');
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: theme('colors.tertiary200');
    background-color: theme('colors.tertiary25');
  }
}

.button--variantOutline1 {
  border: 1px solid theme('colors.primary500');
  background-color: white;
  color: theme('colors.primary500');

  &:focus {
    background-color: theme('colors.primary50');
    box-shadow: 0px 0px 0px 2px theme('colors.primary200');
  }

  &:hover {
    background-color: theme('colors.primary50');
  }

  &:active {
    background-color: theme('colors.primary100');
    border-color: theme('colors.primary600');
    color: theme('colors.primary600');
    box-shadow: none;
  }

  &:disabled {
    color: theme('colors.tertiary200');
    background-color: theme('colors.tertiary25');
    border: none;
  }
}

.button--variantOutline2 {
  border: 1px solid theme('colors.tertiary50');
  background-color: white;
  color: theme('colors.tertiary500');

  &:focus {
    background-color: theme('colors.tertiary25');
    border-color: theme('colors.tertiary500');
    box-shadow: 0px 0px 0px 2px theme('colors.tertiary200');
  }

  &:hover {
    background-color: theme('colors.tertiary25');
    border-color: theme('colors.tertiary100');
  }

  &:active {
    background-color: theme('colors.tertiary50');
    border-color: theme('colors.tertiary200');
    box-shadow: none;
  }

  &:disabled {
    color: theme('colors.tertiary200');
    background-color: theme('colors.tertiary25');
    border: none;
  }
}

.button--variantTransparent {
  color: theme('colors.secondary500');

  &:focus {
    background-color: theme('colors.secondary25');
    outline: 1px solid theme('colors.secondary400');
    box-shadow: inset 0px 0px 0px 2px theme('colors.secondary200');
  }

  &:hover {
    background-color: theme('colors.secondary50');
  }

  &:active {
    color: theme('colors.secondary600');
    background-color: theme('colors.secondary100');
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: theme('colors.tertiary200');
    background-color: transparent;
  }
}

.button--variantTransparentAccent {
  color: theme('colors.primary500');

  &:focus {
    background-color: theme('colors.secondary25');
    outline: 1px solid theme('colors.primary400');
    box-shadow: inset 0px 0px 0px 2px theme('colors.primary200');
  }

  &:hover {
    background-color: theme('colors.primary50');
  }

  &:active {
    color: theme('colors.primary600');
    background-color: theme('colors.primary100');
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: theme('colors.tertiary200');
    background-color: transparent;
  }
}

.button--sizeSmall {
  min-height: 32px;
  min-width: 32px;
  padding: 5px 7px;

  @apply text-caption-sm-semibold;

  &.button svg {
    width: 12px;
    height: 12px;
  }
}

.button--sizeMedium {
  min-height: 40px;
  min-width: 40px;
  padding: 7px 11px;

  @apply text-caption-lg-semibold;

  &.button svg {
    width: 16px;
    height: 16px;
  }
}

.button--sizeLarge {
  min-height: 52px;
  min-width: 52px;
  padding: 13px 15px;

  @apply text-body-sm-semibold;

  &.button svg {
    width: 20px;
    height: 20px;
  }
}
