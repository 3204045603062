.container {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  position: relative;
  box-sizing: border-box;
  height: calc(8px + theme(spacing.6) + theme(spacing.6));
  border: theme(spacing.6) solid transparent;
}
.container:hover {
  & span[data-type='float-top'],
  & span[data-type='float-bottom'] {
    opacity: 1;
  }
}

.bar {
  flex: auto;
  height: 100%;
  border-radius: theme(borderRadius.2);
  background-color: theme(colors.tertiary50);
  overflow: hidden;
}

.progress {
  min-width: calc(theme(borderRadius.2) * 2);
  height: 100%;
  border-radius: theme(borderRadius.2);
  background-color: theme(colors.secondary500);
  transition: width 0.2s ease;
}

.number {
  width: auto;
  font-size: 0.75rem;
  color: theme(colors.tertiary800);

  &[data-type='hidden'] {
    width: 0;
    visibility: hidden;
  }

  &[data-type='fixed-right'] {
    margin-left: theme(spacing.3);
    visibility: visible;
  }

  &[data-type='fixed-bottom'] {
    position: absolute;
    right: 0;
    top: theme(spacing.3);
    text-align: end;
  }

  &[data-type='float-top'],
  &[data-type='float-bottom'] {
    position: absolute;
    opacity: 0;
    padding: theme(spacing.2) theme(spacing.3);
    left: 0;

    text-align: end;
    border-radius: theme(borderRadius.4);
    border-color: theme(colors.tertiary50);
    border-width: 1px;
    border-style: solid;
    box-shadow: theme(boxShadow.4);

    transform: translate(-50%, 0);
    transition: left 0.2s ease, opacity 0.18s ease;
  }

  &[data-type='float-top'] {
    bottom: 1.5rem;
  }

  &[data-type='float-bottom'] {
    top: 1.5rem;
  }
}

.numberIndicator {
  display: inline-block;
  margin: 0px;
  @apply text-caption-sm-semibold;
}
