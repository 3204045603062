.h-50vh {
  height: 50vh !important;
}

.notification-list-wrapper::-webkit-scrollbar {
  width: 10px;
}

.notification-list-wrapper::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #e2e4e8;
  border-radius: 8px;
  max-height: 64px;
  border: 3px solid transparent;
}
