.tabBar {
  & ul {
    height: 56px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    @apply text-body-sm-regular-respon;
    color: theme(colors.tertiary300);
  }

  &[data-equal-item-width='true'] .tabBarItem {
    flex: 1;
  }
}

.tabBarItem {
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid transparent;

  &[data-active='true'] {
    color: theme(colors.tertiary500);
    @apply text-body-sm-semibold-respon;

    border-bottom-color: theme(colors.primary500);
  }

  &:hover[data-active='false'] {
    color: theme(colors.primary400);
  }
}
