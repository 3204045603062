.pagination {
  display: inline-flex;
  flex-direction: row;
  gap: theme(spacing.2);
  align-items: center;
}

.button {
  width: 40px;
  height: 40px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: theme(borderRadius.2);
  cursor: pointer;
  border: none;
  color: theme('colors.tertiary500');
  outline: none;

  @apply text-caption-lg-regular;

  &:focus {
    box-shadow: 0px 0px 0px 2px theme('colors.secondary300');
  }

  &:hover {
    background-color: theme('colors.secondary50');
    color: theme('colors.secondary500');
  }

  &:active {
    background-color: theme('colors.secondary100');
  }

  &:disabled {
    cursor: not-allowed;
    background-color: white;

    .chevron {
      color: theme('colors.tertiary200');
    }
  }
}

.ellipsisButton {
  background-color: transparent;
}

.chevron {
  color: theme('colors.tertiary300');
}

[data-active='true'] {
  &.button {
    background-color: theme('colors.secondary50');
    color: theme('colors.secondary500');
    outline: 1px solid theme('colors.secondary500');

    @apply text-caption-lg-semibold;
  }
}

.more {
  color: theme('colors.tertiary300');
}
