.wrapper {
  display: flex;
  position: relative;
  gap: 4px;
  align-items: center;
}

.caption {
  padding-left: 4px;
  color: theme('colors.tertiary300');
  text-align: left;
  font-size: 14px;
}

.caption_active {
  color: theme('colors.tertiary500') !important;
}

.rating {
  float: left;
  padding: 0;
}

.rating:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rating:not(:checked) > label {
  float: right;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  color: #e2e4e8;
  margin-right: 4px;
}

.rating:not(:checked) > span {
  float: right;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 4px;
}

.rating:not(:checked) > label svg {
  fill: #e2e4e8;
}

.rating:not(:checked) > .bolder svg {
  fill: #a8adb7 !important;
}

.rating:not(:checked) > label:before {
  content: '';
}

.rating > input:checked ~ label {
  color: #f79009;
}
.rating > input:checked ~ label svg {
  fill: #f79009;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #f79009;
}

.rating:not(:checked) > label:hover svg,
.rating:not(:checked) > label:hover ~ label svg {
  fill: #f79009;
}

.rating > input:not(:checked):disabled + label:hover {
  color: #e2e4e8 !important;
  cursor: auto;
}

.rating > input:checked:disabled + label:hover,
.rating > input:checked:disabled ~ label:hover {
  color: #f79009 !important;
  cursor: auto;
}

.wrapper.disabled .rating > label {
  pointer-events: none;
  cursor: default;
}

.rating.disabled > label:hover,
.rating.disabled > label:hover ~ label {
  color: #e2e4e8;
}

.rating.disabled > label svg {
  fill: #e2e4e8;
}
