.mainContainer {
  display: flex;
  flex-direction: row;
  gap: theme('spacing.1');
  align-items: center;

  padding: theme('spacing.5');
  border: 1px solid theme('colors.tertiary100');
  background-color: theme('colors.tertiary25');
}

.seperator {
  @apply text-body-sm-regular;
  color: theme('colors.tertiary50');
}

.path {
  @apply text-caption-lg-regular;
  text-decoration: none;
  color: theme('colors.tertiary500');

  [data-current='true']& {
    @apply text-body-sm-semibold;
    color: theme('colors.secondary500');
  }
}

.more {
  color: theme('colors.tertiary300');
}
