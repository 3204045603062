.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
  height: 8px;
}

.SliderTrack {
  background-color: theme(colors.tertiary50);
  position: relative;
  flex-grow: 1;
  border-radius: theme(borderRadius.7);
  height: 8px;
}

.SliderRange {
  position: absolute;
  background-color: theme(colors.secondary500);
  border-radius: theme(borderRadius.7);
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 24px;
  height: 24px;
  background-color: white;
  box-sizing: border-box;
  border-radius: theme(borderRadius.8);
  border: 1.5px solid theme(colors.secondary500);
}

.SliderThumb:focus {
  outline: none;
}

.numberIndicatorContainer {
  display: none;

  [data-type='bottom'] & {
    display: block;
    position: absolute;
    top: theme(spacing.7);
    background-color: white;

    width: auto;
    font-size: 14px;
    font-weight: 600;
    color: theme(colors.tertiary500);
    transform: translateX(-50%);
  }

  [data-type='float'] & {
    display: block;
    opacity: 0;
    position: absolute;
    bottom: theme(spacing.7);
    padding: theme(spacing.3);
    background-color: white;

    width: auto;
    font-size: 12px;
    font-weight: 700;
    color: theme(colors.tertiary500);
    transform: translateX(-50%);
    transition: opacity 0.2s ease-in-out;

    border-radius: theme(borderRadius.4);
    box-shadow: theme(boxShadow.4);
  }

  [data-type='float'] span:hover:has(> .SliderThumb) + &,
  [data-type='float'] span:not(.SliderTrack):has(~ span:not(.numberIndicator):hover) ~ & {
    z-index: 1;
    opacity: 1;
  }

  [data-type='float'] span:not(.SliderTrack):has(~ span:not(.numberIndicator):hover) ~ &,
  [data-type='float'] span:hover:has(> .SliderThumb) ~ & {
    opacity: 1;
  }
}

.numberIndicator {
  @apply text-caption-lg-bold;
}
